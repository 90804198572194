import React from "react"
import area from "../../styles/icons/area.png"
import frame from "../../styles/icons/frame.png"
import bed from "../../styles/icons/bed.png"
import banera from "../../styles/icons/banera.png"
import noImage from "../../styles/images/no-image-house.png"
import { Link } from "gatsby"
import formatearDinero from "../../utils/Money"
import QuickInfoPropiedad from "../Properties/QuickInfoPropiedad"

const SearchPropiedad = (props) =>{
    const slug=props.propiedad.slug.current
    const image=props.propiedad.image? props.propiedad.image.asset.url+"?w=600": noImage
    return (
    <Link to={`/propiedad/${slug}`} style={{
        color: "var(--darkText)",
        textDecoration: "none"
    }}>
        <div className="property">
            <div className="photoContainer">
                <div class="imgf">
                    <img src={image}  className="propertyImage" alt={"Imagen de "+props.propiedad.title} /> 
                    <div className="top-left">{props.propiedad.modalidad}</div>
                    <div className="text"><span className="seeDetails">VER DETALLES</span></div>
                </div>
            </div>
            <div className="description">
                <QuickInfoPropiedad 
                    superficieConstruccion={props.propiedad.superficieConstruccion} 
                    superficieTerreno={props.propiedad.superficieTerreno} 
                    banos={props.propiedad.banos}
                    dormitorios={props.propiedad.dormitorios} />
            </div>
            <div className="label">
                <span className="namePropiedad"><b>{props.propiedad.title}</b></span><br />
                <span className="precioPropiedad"><b>{`${props.propiedad.unidad==="UF"? "UF: " : "$"}${formatearDinero(props.propiedad.precio)} ${props.propiedad.modalidad!=="Venta"? "al mes" : ""}`}</b></span>
            </div>
        </div>
    </Link>
    )
}

export default SearchPropiedad