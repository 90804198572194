import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import { StaticQuery, graphql } from "gatsby"
import { navigate } from "gatsby"
const publishFunction= () =>{
    navigate("/publicarPropiedad")
}
const WorkWithUs = () =>{
    return (
        <StaticQuery
        query={graphql`
        query WorkWithUsQuery {
            sanityConfiguracionHome{
                textColaboracion
                imageColaboracion{
                    asset{
                        url
                    }
                }
            }
            sanityGlobalConfig{
                logo{
                    asset{
                        url
                    }
                }
            } 
        }
        `}
        render={data => (
        <Container fluid className="finalPicture">
            <Row>
                <Col lg={{span: 7, order:1}} md={{span: 7, order:1}} sm={{span: 12, order: 2}} xs={{span: 12, order: 2}}><img src={data.sanityConfiguracionHome.imageColaboracion.asset.url+"?w=800"} class="imageGirl" alt="Imagen de colaboración" /></Col>
                <Col lg={{span: 5, order:2}} md={{span: 5, order:2}} sm={{span: 12, order: 1}} xs={{span: 12, order: 1}}>
                    <h2 className="question">¿TE AYUDAMOS CON TU PROPIEDAD?</h2>
                    <p style={{textAlign: "center", marginTop: "1em"}}>{data.sanityConfiguracionHome.textColaboracion}</p>
                    <div className="align-center containerButtonFinal">
                        <Button variant="secondary" size="lg" className="buttonYellow" onClick={publishFunction}>¡Publica con nosotros!</Button>
                    
                    </div>

                    <div class="logoFinal"><img class="logoLight" src={data.sanityGlobalConfig.logo.asset.url+"?w=130"} alt="Ícono de Inversur Propiedades" /></div>
                </Col>
            </Row>
        </Container>)} />
    )
}

export default WorkWithUs