import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import InputGroup from "react-bootstrap/InputGroup"
import FormControl from "react-bootstrap/FormControl"
import Button from "react-bootstrap/Button"
import { FaSearch } from "@react-icons/all-files/fa/FaSearch"
import {FaFilter} from "@react-icons/all-files/fa/FaFilter"
import { StaticQuery, graphql } from "gatsby"
import { navigate } from "gatsby"
import YouTube from 'react-youtube'
import "../../styles/_video.css"

const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
			controls: 0,
			rel: 0,
			showinfo: 0,
			mute: 1,
			modestbranding:1,
			iv_load_policy: 3,
			playsinline:1,
    },
  };

  const _onReady=(event)=>{
    // access to player in all event handlers via event.target
    event.target.playVideo();
  }

function matchYoutubeUrl(url){
    var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
     return (url.match(p)) ? RegExp.$1 : false ;
}


const LandingSearch=()=>{
    const [size,setSize]=React.useState({
        aspectRatio: 16/9,
		videoHeight: 10
    })
    const updateDimensions=()=>{
        const { aspectRatio } = size
		const containerWidth = (typeof window !== 'undefined')? window.innerWidth : 1000
		const containerHeight = (typeof window !== 'undefined')? window.innerHeight: 400
		const containerAspectRatio = containerWidth / containerHeight

		let videoHeight = containerHeight
		let videoWidth = containerWidth
		let videoY = 0
		let videoX = 0

		if (containerAspectRatio > aspectRatio) {
			videoHeight = containerWidth / aspectRatio
			videoY = (videoHeight - containerHeight) / -2
		} else {
			videoWidth = containerHeight * aspectRatio
			videoX = (videoWidth - containerWidth) / -2
        }
        if (containerWidth<=760) videoY=videoY-40;
        setSize({
            videoHeight,
			videoWidth,
			videoY,
			videoX
        })
        console.log(videoHeight+" "+videoWidth)
        
}
const { videoHeight, videoWidth, videoX, videoY } = size
    React.useEffect(()=>{
        updateDimensions();
        if (typeof window !== 'undefined'){
            window.addEventListener("resize", updateDimensions)
        }
    },[])

    React.useEffect(()=>{
        return () => { 
            if (typeof window !== 'undefined'){
                window.removeEventListener("resize", updateDimensions)
            }
         }
    },[])
    return (
        <StaticQuery
        query={graphql`
          query LandingSearchQuery {
            sanityConfiguracionHome{
                tipoMedia
                mainVideo
                lema
                mainImage{
                  asset{
                    url
                  }
                }
            }
          }
        `}
        render={data => (
        <div>
        {data.sanityConfiguracionHome.tipoMedia==="Imagen"? 
        (
            <div className="landingSearch" style={{
                backgroundImage:`url(${data.sanityConfiguracionHome.mainImage.asset.url})`}}>
                <MainContent lema={data.sanityConfiguracionHome.lema} />
            </div>
            ): (
                <div className="containerVideo ">
                    <MainContent lema={data.sanityConfiguracionHome.lema} />
                    <div className="videoContainer" style={{
						width: videoWidth + 'px',
						height: videoHeight + 'px',
						top: videoY + 'px',
						left: videoX + 'px'
					}}>
                    <YouTube className={"videoIframe"}
                        containerClassName={"videoInnerContainer"}
                        videoId={matchYoutubeUrl(data.sanityConfiguracionHome.mainVideo)} opts={opts} onReady={_onReady} />
                    

                    </div>
                 
                </div>
                 
                
            
        )}
        </div>
        )} />
    )
}



const MainContent=(props)=>{
    const [search,setSearch]=React.useState("")   
    const searchFunction= () =>{
        navigate("/buscarPropiedad?busqueda="+search)
    }
    const handleChange=(e)=>{
        setSearch(e.target.value)
    }
    const filterFunction=()=>{
        navigate("/buscarPropiedad?filters=true")
    }
    return(
        <Container fluid>
        <Row className="justify-content-center ">
            <Col className="searchRow" lg={8} md={12} sm={12}>
                <InputGroup className="mb-3">
                    <FormControl
                        className="mainInput"
                        value={search}
                        onChange={handleChange}
                        placeholder={props.lema}
                        aria-label={props.lema}
                        onFocus={(e) => e.target.placeholder = ""}
                        onBlur={(e) => e.target.placeholder = props.lema}
                    />
                    <InputGroup.Append>
                    <Button variant="outline-secondary" className="mainButton" onClick={searchFunction}><FaSearch /></Button>
                    <Button variant="outline-secondary" className="filterButton" onClick={filterFunction}><FaFilter /></Button>
                    </InputGroup.Append>
                </InputGroup>
            </Col>
        </Row>
    </Container>  
    )
}

export default LandingSearch