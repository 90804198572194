import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { StaticQuery, graphql } from "gatsby"

import noImage from "../../styles/images/no-image-asesor.png"

const OurTeam = () =>{
    return(
        <StaticQuery
        query={graphql`
          query OurTeamQuery {
            sanityConfiguracionHome{
              textEquipo
              equipo{
                name
                image{
                  asset{
                    url
                  }
                }
              }
            }
          }
        `}
        render={data => ( 
        <div className="landingAbout">
          <h2 style={{textAlign: "center", marginTop: "1em", marginBottom: "1em", marginLeft:"1em", marginRight:"1em"}}>NUESTRO EQUIPO</h2>
          <Container>
           <Row className="justify-content-center">
               
                {data.sanityConfiguracionHome.equipo.map(miembro =>{
                    return (
                        <Col md={2} sm={4} xs={6} style={{marginBottom: "1em"}}>
                            <img className="fotoEquipo" src={miembro.image? miembro.image.asset.url+"?w=200" : noImage }></img>
                        </Col>
                    )
                })}
              
            </Row>
            </Container>
            <p style={{textAlign: "center", marginTop: "2em", marginBottom: "1em"}}>{data.sanityConfiguracionHome.textEquipo}</p>
            
        </div>) }/>
    )
}

export default OurTeam