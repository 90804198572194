import * as React from "react"
import Template from "../components/Template"
import SEO from "../components/SEO"
import LandingSearch from "../components/Landing/LandingSearch"
import LandingAbout from "../components/Landing/LandingAbout"
import OurProperties from "../components/Landing/OurProperties"
import WorkWithUs from "../components/Landing/WorkWithUs"
import OurTeam from "../components/Landing/OurTeam"
// markup
const IndexPage = () => {
  return (
    <Template>
      <SEO title="Home"/>
      <LandingSearch />
      <LandingAbout/>
      <OurProperties />
      <OurTeam />
      <WorkWithUs />
      
    </Template>
  )
}


export default IndexPage

