import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import SearchPropiedad from "../Search/SearchPropiedad"
import { StaticQuery, graphql } from "gatsby"

const OurProperties = () =>{
    return ( <StaticQuery
        query={graphql`
          query OurPropertiesQuery {
            sanityConfiguracionHome{
                textPropiedades
                propiedadesDestacadas {
                  title
                  slug{
                    current
                  }
                  dormitorios
                  banos
                  precio
                  unidad
                  superficieTerreno
                  superficieConstruccion
                  modalidad
                  image{
                    asset{
                      url
                    }
                  }
                }
              }
          }
        `}
        render={data => ( 
        <Container className="ourProperties">
            <Row className="justify-content-center">
                <Col md={12} sm={12}>
                    <h2 className="align-center tituloOurPropiedades">PROPIEDADES DESTACADAS</h2>
                    <p style={{textAlign: "center", marginBottom: "2em"}}>{data.sanityConfiguracionHome.textPropiedades}</p>
                    <Row className="justify-content-center">
                        { data.sanityConfiguracionHome.propiedadesDestacadas.map(item => (
                            <Col md={4} sm={12}>
                                <SearchPropiedad propiedad={item}/>
                            </Col>
                        ))}
                        
                    </Row>
                </Col>
            </Row>
        </Container>) }/>)
}

export default OurProperties