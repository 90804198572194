import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { StaticQuery, graphql } from "gatsby"

const LandingAbout= ()=> {
    return(
        <StaticQuery
        query={graphql`
          query LandingAboutQuery {
            sanityConfiguracionHome{
                  slogan
                  textPresentacion
                  imagePresentacion{
                    asset{
                      url
                    }
                  }
            }
          }
        `}
        render={data => ( 
        <div className="landingAbout">
          <h1 style={{textAlign: "center", marginTop: "2em", marginBottom: "1em"}}>{data.sanityConfiguracionHome.slogan}</h1>
            <Container>
            <Row className="justify-content-around">
                <Col md={10} sm={10}>
                    <Row>
                        <Col md={6} sm={12} className="align-center textPresentacion">{data.sanityConfiguracionHome.textPresentacion}</Col>
                        <Col md={6} sm={12}><img className="imagePresentacion" src={data.sanityConfiguracionHome.imagePresentacion.asset.url+"?w=600"} alt="Keys for a new house"/></Col>
                    </Row>
                </Col>
            </Row>
            </Container>
        </div>) }/>
    )
}

export default LandingAbout